import React, { useContext, useEffect, useState } from 'react';

import classNames from 'classnames';
import ButtonSecondaryEllipse from '../ButtonSecondaryEllipse';
import useIntersectionObserver from '../../hooks/useIntersectionObserver';
import { GradientContext } from '../../containers/BasePage';
import { motion } from 'framer-motion';
import { trackCarouselNextArrow } from '../../utils/dataLayer';
import s from './SlideShowNav.module.scss';
import { useSwiper } from 'swiper/react';

const SlideShowNav = ({
  indicators = [],
  indicatorIndex,
  setIndicatorIndex,
  color,
  isVisible,
  ...props
}) => {
  const [ref, _isVisible] = useIntersectionObserver(0, undefined, true);
  const gradient = useContext(GradientContext);
  const swiper = useSwiper();

  const variants = {
    active: {
      transition: {
        duration: 1,
        type: 'spring',
        stiffness: 200,
      },
    },
  };

  const handleNext = () => {
    if (indicatorIndex === indicators.length - 1) {
      return;
    }

    trackCarouselNextArrow();
    const newIndex = indicatorIndex + 1;
    setIndicatorIndex(newIndex);
    swiper.slideNext();
  };

  const handlePrev = () => {
    if (indicatorIndex === 0) {
      return;
    }

    const newIndex = indicatorIndex - 1;
    setIndicatorIndex(newIndex);
    swiper.slidePrev();
  };

  const rootClasses = classNames(s.Root, s[gradient], {
    [s.Visible]: isVisible,
    [s.White]: color !== 'black',
  });

  return (
    <div className={rootClasses} {...props} ref={ref}>
      <div className={s.SlideShowIndicatorContainer}>
        {indicators.map((indicator, i) => {
          return (
            <div
              key={i}
              data-index={i}
              data-indicator-index={indicatorIndex}
              className={s.SlideShowIndicator}>
              {i === indicatorIndex && (
                <motion.div
                  className={s.Highlighted}
                  variants={variants}
                  animate={i === indicatorIndex}
                  layoutId="highlight"
                />
              )}
            </div>
          );
        })}
      </div>
      <div className={s.SlideShowButtons}>
        <ButtonSecondaryEllipse
          icon={
            color !== 'black'
              ? 'icon-dropdown-arrow-left-black'
              : 'icon-dropdown-arrow-left-white'
          }
          modifiers={[
            `${color !== 'black' && 'SlideShowNavWhite'}`,
            'SlideShowNav',
          ]}
          onClick={handlePrev}
          disabled={indicatorIndex === 0}>
          <span className="sr-only"> Previous Item</span>
        </ButtonSecondaryEllipse>
        <ButtonSecondaryEllipse
          icon={
            color !== 'black'
              ? 'icon-dropdown-arrow-right-black'
              : 'icon-dropdown-arrow-right-white'
          }
          modifiers={[
            `${color !== 'black' && 'SlideShowNavWhite'}`,
            'SlideShowNav',
          ]}
          onClick={handleNext}
          disabled={indicatorIndex === indicators.length - 1}>
          <span className="sr-only"> Next Item</span>
        </ButtonSecondaryEllipse>
      </div>
    </div>
  );
};

SlideShowNav.propTypes = {};

SlideShowNav.defaultProps = {};

export default SlideShowNav;
